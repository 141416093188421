import {
	FILTER_SEARCH_REPORT,
	CLEAR_FILTER_SEARCH_REPORT,
	GET_ISSUING_REPORT,
	GET_ISSUING_REPORT_SUCCESS,
	GET_ISSUING_REPORT_FAILED,
	GET_REDEEM_REPORT,
	GET_REDEEM_REPORT_SUCCESS,
	GET_REDEEM_REPORT_FAILED,
	GET_VOUCHER_BALANCE_REPORT,
	GET_VOUCHER_BALANCE_REPORT_SUCCESS,
	GET_VOUCHER_BALANCE_REPORT_FAILED,
	GET_MEMBER_BALANCE_REPORT,
	GET_MEMBER_BALANCE_REPORT_SUCCESS,
	GET_MEMBER_BALANCE_REPORT_FAILED,
	GET_REFERRAL_REPORT,
	GET_REFERRAL_REPORT_SUCCESS,
	GET_REFERRAL_REPORT_FAILED,
	GET_POINT_TRANSACTION_REPORT,
	GET_POINT_TRANSACTION_REPORT_SUCCESS,
	GET_POINT_TRANSACTION_REPORT_FAILED,
	GET_POINT_TRANSFER_REPORT,
	GET_POINT_TRANSFER_REPORT_SUCCESS,
	GET_POINT_TRANSFER_REPORT_FAILED,
	DOWNLOAD_SUCCESS,
	GET_PARTNER_REPORT,
	GET_PARTNER_REPORT_SUCCESS,
	GET_PARTNER_REPORT_FAILED,
	HIDE_MESSAGE,
	GET_REDEEM_PARTNER_REPORT,
	GET_REDEEM_PARTNER_REPORT_SUCCESS,
	GET_REDEEM_PARTNER_REPORT_FAILED,
	GET_GIFTAWAY_VOUCHER_REPORT,
	GET_GIFTAWAY_VOUCHER_REPORT_SUCCESS,
	GET_GIFTAWAY_VOUCHER_REPORT_FAILED,
	UPLOAD_TRANSACTION_APPROVAL_REPORT,
	UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED,
	UPLOAD_TRANSACTION_APPROVAL_REPORT_SUCCESS,
	APPROVE_REJECT_APPROVAL,
	APPROVE_REJECT_APPROVAL_SUCCESS,
	APPROVE_REJECT_APPROVAL_FAILED,
	RESET_STATUS,
	GET_LOYALTI_POINT_ACTIVITY,
	GET_LOYALTI_POINT_ACTIVITY_SUCCESS,
	GET_LOYALTI_POINT_ACTIVITY_FAILED
} from "constants/ActionTypes";
// import { Result } from "antd";
// import moment from 'moment';

const INIT_STATE = {
	loader: false,
	alertMessage: "",
	showMessage: false,
	result: [],
	resultApproval: [],
	resultData: null,
	listPointTransfer: [],
	recordInfo: {
		totalRecords: 0,
		page: 0,
		nrecords: 0,
	},
	filterAndSort: {
		pagination: null,
		filters: null,
		sorter: null,
		search: null,
		searchPhone: null,
		trxType: null,
		startDate: null,
		endDate: null,
		customFilter: null,
	},
	downloadData: [],
	downloadPointTransfer: [],
	downloadApprovalReport: [],
	response: {},
	totalBillingAmount: "",

	approveRejectSuccess: false,
	approveRejectFailed: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case FILTER_SEARCH_REPORT: {
			return {
				...state,
				filterAndSort: action.payload,
			};
		}

		case CLEAR_FILTER_SEARCH_REPORT: {
			return {
				...state,
				filterAndSort: {
					pagination: null,
					filters: null,
					sorter: null,
					search: null,
					searchPhone: null,
					startDate: null,
					endDate: null,
					customFilter: null,
				},
			};
		}

		case HIDE_MESSAGE: {
			return {
				...state,
				alertMessage: "",
				showMessage: false,
				loader: false,
			};
		}

		case RESET_STATUS: {
			return {
				...state,
				approveRejectSuccess: false,
				approveRejectFailed: false,
			};
		}

		case GET_ISSUING_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_LOYALTI_POINT_ACTIVITY: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_REDEEM_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_VOUCHER_BALANCE_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_MEMBER_BALANCE_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_REFERRAL_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_POINT_TRANSACTION_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_POINT_TRANSFER_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadPointTransfer: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					listPointTransfer: [],
					recordInfo: {},
					downloadPointTransfer: [],
				};
			}
		}

		case GET_PARTNER_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_REDEEM_PARTNER_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		// Response
		case GET_ISSUING_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_ISSUING_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case GET_REDEEM_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_REDEEM_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case GET_VOUCHER_BALANCE_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_VOUCHER_BALANCE_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case GET_MEMBER_BALANCE_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_MEMBER_BALANCE_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case GET_REFERRAL_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_REFERRAL_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case GET_POINT_TRANSACTION_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_POINT_TRANSACTION_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case GET_POINT_TRANSFER_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				listPointTransfer: action.payload.pointTransferHistoryList,
				// listPointTransfer: result,
				recordInfo: action.payload.recordInfo,
				response: action.payload,
			};
		}

		case GET_POINT_TRANSFER_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				// showMessage: true
			};
		}

		case GET_PARTNER_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_PARTNER_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case DOWNLOAD_SUCCESS: {
			return {
				...state,
				loader: false,
				downloadData: action.payload.result,
				downloadPointTransfer: action.payload.pointTransferHistoryList,
				downloadApprovalReport: action.payload,
			};
		}

		case GET_REDEEM_PARTNER_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_REDEEM_PARTNER_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case GET_GIFTAWAY_VOUCHER_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadData: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					result: [],
					recordInfo: {},
					downloadData: [],
				};
			}
		}

		case GET_GIFTAWAY_VOUCHER_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_GIFTAWAY_VOUCHER_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case UPLOAD_TRANSACTION_APPROVAL_REPORT: {
			if (action.payload.isDownload) {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					downloadApprovalReport: [],
				};
			} else {
				return {
					...state,
					loader: true,
					showMessage: false,
					alertMessage: "",
					resultApproval: null,
					recordInfo: {},
					downloadApprovalReport: [],
				};
			}
		}

		case UPLOAD_TRANSACTION_APPROVAL_REPORT_SUCCESS: {
			return {
				...state,
				loader: false,
				resultApproval: action.payload,
				recordInfo: action.payload.recordInfo,
			};
		}

		case UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case APPROVE_REJECT_APPROVAL: {
			return {
				...state,
				loader: true,
				showMessage: false,
				alertMessage: "",
				resultData: null,
			};
		}

		case APPROVE_REJECT_APPROVAL_SUCCESS: {
			return {
				...state,
				loader: false,
				approveRejectSuccess: true,
				approveRejectFailed: false,
				resultData: action.payload,
			};
		}

		case APPROVE_REJECT_APPROVAL_FAILED: {
			return {
				...state,
				loader: false,
				approveRejectSuccess: false,
				approveRejectFailed: true,
				alertMessage: action.payload,
				showMessage: true,
				resultData: null,
			};
		}


		case GET_LOYALTI_POINT_ACTIVITY_SUCCESS: {
			return {
				...state,
				loader: false,
				result: action.payload.result,
				recordInfo: action.payload.recordInfo,
			};
		}

		case GET_LOYALTI_POINT_ACTIVITY_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		default:
			return state;
	}
};
